import store from "@/store"
import { WEBSOCKET_ACTIONS, TABLE_NAMES } from "@/constants"
export default class WebSocketApi {
    private connection:  WebSocket| null = null
    private static instance: WebSocketApi

    
    private constructor(){
        console.log('Instanciated WebSocketApi')
    }
    /**
     * The static method that controls the access to the singleton instance.
     *
     * This implementation let you subclass the Singleton class while keeping
     * just one instance of each subclass around.
     */
  public static getInstance (): WebSocketApi {
    if (!WebSocketApi.instance) {
      WebSocketApi.instance = new WebSocketApi()
    }

    return WebSocketApi.instance
  }

    public connect =(): void =>{
        console.log("Starting Connection to Websocket Server")
        try{
            const websocketEndpoint = store.getters['configuration/getWebsocketUrl'];
            this.connection = new WebSocket(websocketEndpoint);
            this.connection.onopen = this.handleSocketOpen
            this.connection.onclose = this.handleSocketClose
            this.connection.onmessage = this.handleSocketMessage
        } catch(error){
            console.log(error)
        }
    }
    private  handleSocketOpen = (event : Event): void =>{
        console.log(event)
        console.log("Successfully connected to the echo websocket server...") 
        store.dispatch('webSocketStatus/updateWebsocketConnected',true)
        this.sendWebSocketMessage(
          `{"action": "${WEBSOCKET_ACTIONS.DATA_REQUEST}", "tableName":"${TABLE_NAMES.APP_STATUS}"}`
        );
        this.sendWebSocketMessage(
          `{"action": "${WEBSOCKET_ACTIONS.DATA_REQUEST}", "tableName":"${TABLE_NAMES.DEVICES}"}`
        );
    }

    private handleSocketClose =(event : Event): void =>{
        console.log(event)
        console.log("Successfully closed to the echo websocket server...")
        store.dispatch('webSocketStatus/updateWebsocketConnected',false);
        this.connect()
    }

    private handleSocketMessage =(event : MessageEvent): void =>{
        console.log("Socket has recieved a message..")
        const date = new Date().toLocaleString()
        console.log(date)
        const socketMessage = JSON.parse(event.data);
        const tableName = socketMessage["tableName"];
        switch (tableName) {
            case "analytics-manager-devices-applications-status": {
                store.dispatch("applicationStatus/updateData", socketMessage["data"]);
                store.dispatch("applicationStatus/updateDate", date);
                break;
            }
          case "analytics-manager-devices": {
              store.dispatch("deviceInformation/updateData", socketMessage["data"]);
              store.dispatch("deviceInformation/updateDate", date);
                break;
          }
          default: {
                break;
          }
        }
    }

    public sendWebSocketMessage =(message: string): void => {
        try{
            console.log(this.connection);
            this.connection!.send(message);
            console.log("Message successfully sent: ", message)
        } catch(error){
            console.log("Error sending message:")
            console.log(error)   
        }
     
    }
  
}