import AxiosConfig  from './axiosConfig';
import store from '@/store';

import MsalUtils from '@/utils/msal';

import { UserStatus } from '@/constants';

export default {
    async getData(service: string, endpoint: any, params: any = null, pathParams: any = null): Promise<any> {
        // Check User is logged in
        if (await MsalUtils.getInstance().aquireToken(true)){
            // If the user is logged in, continue retrieving the data
            const getEndpoint: Function = store.getters['configuration/getEndpoint'];
            const resource: string = `${getEndpoint(service, endpoint)}/${pathParams || ''}`;
            
            return AxiosConfig.getInstance().get(service, resource, params)
        }
    },
};
