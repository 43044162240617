<template>
  <div
    id="div-table-metric-id">
    <b-overlay
      :show="isLoading"
      rounded="sm">
      <b-card
        header-border-variant="secondary"
        class="table-metric-card">
        <div
          class="font-size-xx-large"
          id="table-title-id">
          {{ title }}
        </div>
         <div
          class="font-size-large"
          id="table-date-id">
          {{lastUpdated}}{{ date }}
        </div>
        <div
          class="font-size-large"
          id="table-description-id">
          {{ description }}
        </div>
        <div
          class="font-size-large"
          id="table-value-id">
          <b-table 
          striped 
          hover
          bordered
          :items="data.items"
          :fields="data.fields"></b-table>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
export default {
  name: 'TableMetric',
  props: [
    'title',
    'description',
    'lastUpdated',
    'data',
    'date',
    'isLoading'
  ],
}
</script>
