<template>
  <div
    id="div-home-tab-manager"
    class="text-centre">
    <!-- Tab to switch between Application Status and Devices Information-->
    <b-row>
      <b-col
        offset="1"
        cols="10">
        <b-tabs
          class="mt-4">
          <b-tab title="Application Status" lazy>
              <ApplicationsStatus />
          </b-tab>
          <b-tab title="Devices" lazy>
              <DeviceInformation />
          </b-tab>
          <b-tab title="Applications Metrics">
              <ApplicationsMetrics />
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ApplicationsStatus from './ApplicationsStatus.vue'
import DeviceInformation from './DeviceInformation.vue'
import ApplicationsMetrics from './ApplicationsMetrics.vue'

export default {
  name: 'HomeTabManager',
  components: {
    ApplicationsStatus,
    DeviceInformation,
    ApplicationsMetrics
  }
}
</script>
