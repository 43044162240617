import metricApi from "@/api/eventApi";

import { ENDPOINTS, SERVICES } from "@/constants";

// State
const state = () => ({
  data: null, // Object for storing the metric data.
  requestId: -1,
  isLoading: false
});

// Getters
const getters = {
  getData(state: any): any {
    return state.data;
  },

  getIsLoading(state: any): Boolean {
    return state.isLoading;
  }
};

// Mutations
const mutations = {
  setData(state: any, data: any) {
    state.data = data
  },

  setIsLoading(state: any, loading: Boolean) {
    state.isLoading = loading
  },

  cancelRequest(state: any) {
    // Access Axios to cancel the current request with the requestId

    // Reset the requestId
    state.requestId = -1
  }
};

// Actions
const actions = {
  async loadData({ commit }: any) {
    // For each metric (in the config file), make an api call for it's data.
    try {
      commit("setIsLoading", true);

      const response = await metricApi.getData(
        SERVICES.EVENT,
        ENDPOINTS.APPLICATIONS
      );
      console.log('loadData:: response', response)

      // Commit the data to the store.
      commit("setData", response.data);
    } catch (error) {
      console.error(error);
    } finally {
      commit("setIsLoading", false);
    }
  },

  cancelRequest({ commit }: any): void {
    commit("cancelRequest");
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
