import Vue from 'vue'
import App from './App.vue'
import Vuex from 'vuex'
import store from './store/index'


import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import './styles/global.css'

Vue.config.productionTip = false

Vue.use(Vuex)
Vue.use(BootstrapVue) // Make BootstrapVue available throughout the project
Vue.use(IconsPlugin) // Install the BootstrapVue icon components plugin

new Vue({
  store,
  render: (h) => h(App),
}).$mount("#app");
