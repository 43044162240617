import WebSocketApi from "@/api/webSocketApi";
import metricApi from "@/api/eventApi";

import { ENDPOINTS, SERVICES } from "@/constants";

// State
const state = () => ({
  data: {}, // Object for storing the metric data.
  requestId: -1,
  isLoading: false,
  date: ""
});

// Getters
const getters = {
  getData(state: any): any {
    return state.data;
  },

  getDate(state: any): String {
    return state.date;
  },

  getIsLoading(state: any): Boolean {
    return state.isLoading;
  }
};

// Mutations
const mutations = {
  setData(state: any, data: any) {
    state.data = data
  },

  setDate(state: any, date: String) {
    state.date = date
  },

  setIsLoading(state: any, loading: Boolean) {
    state.isLoading = loading
  },

  cancelRequest(state: any) {
    // Access Axios to cancel the current request with the requestId

    // Reset the requestId
    state.requestId = -1
  }
};

// Actions
const actions = {
  
  cancelRequest({ commit }: any): void {
    commit("cancelRequest");
  },

  updateData({ commit }: any, data: any): void {
    commit("setData", data);
  },

  updateDate({ commit }: any, date: String): void {
    commit("setDate", date);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
