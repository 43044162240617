/* eslint-disable @typescript-eslint/no-explicit-any */
import store from "@/store";
import {
  PublicClientApplication,
  IPublicClientApplication,
  AccountInfo,
  AuthenticationResult,
} from "@azure/msal-browser";

import { UserStatus } from '@/constants';

export default class MsalUtils {
  private static instance: MsalUtils;

  private msalInstance: IPublicClientApplication | undefined;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private sso: Record<string, any> | null;

  /**
   * The Singleton's constructor should always be private to prevent direct
   * construction calls with the `new` operator.
   */
  private constructor() {
    this.sso = null;
  }

  /**
   * The static method that controls the access to the singleton instance.
   */
  public static getInstance(): MsalUtils {
    if (!MsalUtils.instance) {
      MsalUtils.instance = new MsalUtils();
    }

    return MsalUtils.instance;
  }

  public setSSO(sso: Record<string, any>): void {
    this.sso = sso;

    this.msalInstance = new PublicClientApplication({
      auth: this.sso?.auth,
    });

    this.setAccount();
  }

  private setAccount(): void {
    if (this.msalInstance) {
      const currentAccounts: AccountInfo[] = this.msalInstance.getAllAccounts();

      // We only expect one account, if not, just go through the normal process
      if (currentAccounts && currentAccounts.length === 1) {
        const activeAccount: AccountInfo|any = currentAccounts.shift() as AccountInfo;
        // eslint-disable-next-line dot-notation
        if (
          this.sso &&
          activeAccount.idTokenClaims 
          && activeAccount.idTokenClaims['aud'] === this.sso.auth.clientId
        ) {
          this.msalInstance.setActiveAccount(activeAccount);
        }
      }
    }
  }

  public async aquireToken(renew: boolean = false): Promise<boolean> {
    try {
      // eslint-disable-next-line
      const response:
        AuthenticationResult
        | undefined = await this.msalInstance?.acquireTokenSilent(
        this.sso?.request
      ); // if this.msalInstance is null or undefined it will return undefined.

      if (response !== undefined) {
        let name: string | "" | undefined = response?.account?.username;
        if (name) {
          name = name.substring(0, name.indexOf("."));
        }

        store.dispatch("user/updateUser", {
          name: name ?? "", // returns ' ' if idTokenClaims is null or undefined.
          email: response?.account?.username ?? "",
          accessToken: response?.accessToken ?? "",
          idToken: response?.idToken ?? ""
         });

        store.dispatch("user/updateStatus", UserStatus.SignedIn);

        return true;
      } else {
        return this.signIn(renew);
      }
    } catch (error) {
      return this.signIn(renew);
    }
  }

  public async signOut(): Promise<void> {
    try {
      await this.msalInstance?.logout()

      store.dispatch("user/resetUser");
    } catch (error) {
      console.error(error)
    }
  }

  private async signIn(renew: boolean = false): Promise<boolean> {
    try {
      // eslint-disable-next-line
      const response: AuthenticationResult | undefined = await this.msalInstance?.acquireTokenPopup(
        this.sso?.request
      ); // if this.msalInstance is null or undefined it will return undefined.

      if (response !== undefined) {
        let name: string | "" | undefined = response?.account?.username;
        if (name) {
          name = name.substring(0, name.indexOf("."));
        }

        store.dispatch('user/updateUser', {
          name: name ?? "", // returns ' ' if idTokenClaims is null or undefined.
          email: response?.account?.username ?? "",
          accessToken: response?.accessToken ?? "",
          idToken: response?.idToken ?? "",
        });

        // Let the store know that their is no sign error
        // store.dispatch("user/updateSignInError", false);
        store.dispatch("user/updateStatus", UserStatus.SignedIn);

        if (this.msalInstance && response.account) {
          this.msalInstance.setActiveAccount(response.account);
        }

        return true;
      } else {
        // store.dispatch("user/updateSignInError", false);
        store.dispatch("user/updateStatus", renew ? UserStatus.TokenNotRenewable : UserStatus.ErrorSignedIn);
        return false;
      }
    } catch (error) {
      store.dispatch("user/updateStatus", renew ? UserStatus.TokenNotRenewable : UserStatus.ErrorSignedIn);
      return false;
    }
  }
}
