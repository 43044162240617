
// State
const state = () => ({
  uiTexts: {}
});
  
  // Getters
  const getters = {
    getText: (state: any) => (key: string) => {
      return key in state.uiTexts ? state.uiTexts[key] : null
    }
  };
  
  // Mutations
  const mutations = {
    setTexts (state: any, texts: Record<string, string>): void {
        state.uiTexts = texts
    }
  }
  
  // Actions
  const actions = {
    async loadTexts ({ commit }: any) {
      console.log('loadTexts')

      try {
        const response = await fetch('/strings/texts.json')
        const data: Record<string, string> = await response.json()

        commit('setTexts', data);
      } catch (error) {
        console.error(error)
      }
    }
  };

  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  };
  