import AxiosConfig from "@/api/axiosConfig";
import MsalUtils from "@/utils/msal";

// State
const state = () => ({
  configTitle: "",
  configVersion: {},
  configServices: [],
  configSso: null,
  configWebsocket: {}
});
  
  // Getters
  const getters = {
    getTitle(state: any): string {
      return state.configTitle;
    },

    getVersion(state: any): any {
      return state.configVersion;
    },

    getServices(state: any): any[] {
      return state.configServices;
    },

    getWebsocketUrl(state: any): any[] {
      return state.configWebsocket.socketUrl;
    },

    getEndpoint(state: any) {
      return (serviceName: string, endpointName: string) => {
        // Filter out all the configServices.name's that == the given serviceName
        // Map that value to the endpoint.
        // There should only be one item in the resulting array, so return that.
        const value = state.configServices
          .filter(function(service:any) {return service.name == serviceName })
          .map(function(service:any){return service.endpoints[endpointName] })
        return value[0];
      };
    },

    getFullUrlEndpoint(state: any) {
      return (serviceName: string, endpointName: string) => {
        // Filter out all the configServices.name's that == the given serviceName
        // Map that value to the url.
        // There should only be one item in the resulting array, so return that.
        const value = state.configServices
          .filter(function(service:any) {return service.name == serviceName })
          .map(function(service:any){return service.api_url + "/" + service.endpoints[endpointName] })

        return value[0];
      };
    },
  };
  
  // Mutations
  const mutations = {
    setConfiguration (state: any, newConfiguration: any): void {
        state.configTitle = newConfiguration.title
        state.configVersion = newConfiguration.version
        state.configServices = newConfiguration.services
        state.configSso = newConfiguration.sso
        state.configWebsocket = newConfiguration.websocket

        MsalUtils.getInstance().setSSO(state.configSso);

        AxiosConfig.getInstance().updateInstance(state.configServices)
    }
  }
  
  // Actions
  const actions = {
    async updateConfiguration ({ commit }: any) {
      console.log('configuration.ts:: Loading configuration')
      let currentConfiguration;
      try {
        const response = await fetch('/config/config.json')
        currentConfiguration = await response.json()
      } catch (error) {
        console.error(error)
        return;
      }
      console.log('configuration.ts:: Updating configuration')
      commit('setConfiguration', currentConfiguration);
    }
  };
  
  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
  };
  