import CHART_DATA from "../../assets/config/metric-data-dev.json";
import metricApi from "../../api/eventApi";
import { ENDPOINTS, SERVICES } from "../../constants";
import Vue from "vue";

// State
const state = () => ({
  dateRange: [],
  softwareVersion: "",
  hardwareVersion: "",
  country: "",
  searchTriggered: false, // Conditional for rendering the metric panel.
  metricData: {}, // Object for storing the metric data.
  showLoadingOverlay: false,
});

// Getters
const getters = {
  getDateRange(state: any) {
    return state.dateRange;
  },

  getSearchTriggered(state: any) {
    return state.searchTriggered;
  },

  getSoftwareVersion(state: any) {
    return state.softwareVersion;
  },

  getHardwareVersion(state: any) {
    return state.hardwareVersion;
  },

  getCountry(state: any) {
    return state.country;
  },

  getMetricData(state: any) {
    return state.metricData;
  },

  getShowLoadingOverlay(state: any) {
    return state.showLoadingOverlay;
  },
};

// Mutations
const mutations = {
  setDateRange(state: any, newDateRange: string[]) {
    state.dateRange = newDateRange;
  },

  setSearchTriggered(state: any, newSearchState: boolean) {
    state.searchTriggered = newSearchState;
  },

  setHardwareVersion(state: any, newHardwareFilter: boolean) {
    state.hardwareVersion = newHardwareFilter;
  },

  setSoftwareVersion(state: any, newSoftwareFilter: boolean) {
    state.softwareVersion = newSoftwareFilter;
  },

  setCountry(state: any, newCountryFilter: boolean) {
    state.country = newCountryFilter;
  },

  setShowLoadingOverlay(state: any, show: boolean) {
    state.showLoadingOverlay = show;
  },

  setMetricData(state: any, newMetricData: any) {
    const currentEndpoint = newMetricData[0];
    const currentData = newMetricData[1];
    Vue.set(state.metricData, currentEndpoint, currentData[currentEndpoint]);
  },
};

// Actions
const actions = {
  updateDateRange({ commit }: any, newDateRange: string[]) {
    console.log(
      "search.js:: Updating DateRange in the search store to: ",
      newDateRange
    );
    commit("setDateRange", newDateRange);
  },

  updateSearchTriggered({ commit }: any, newSearchState: boolean) {
    console.log(
      "search.js:: Updating SearchTriggered in the search store to: ",
      newSearchState
    );
    commit("setSearchTriggered", newSearchState);
  },

  updateShowLoadingOverlay({ commit }: any, show: boolean) {
    console.log(
      "search.js:: Updating ShowLoadingOverlay in the search store to: ",
      show
    );
    commit("setShowLoadingOverlay", show);
  },

  updateHardwareVersion({ commit }: any, newHardwareFilter: "") {
    console.log(
      "search.js:: Updating HardwareVersion in the search store to: ",
      newHardwareFilter
    );
    commit("setHardwareVersion", newHardwareFilter);
  },

  updateSoftwareVersion({ commit }: any, newSoftwareFilter: boolean) {
    console.log(
      "search.js:: Updating SoftwareVersion in the search store to: ",
      newSoftwareFilter
    );
    commit("setSoftwareVersion", newSoftwareFilter);
  },

  updateCountry({ commit }: any, newCountryFilter: boolean) {
    console.log(
      "search.js:: Updating Country in the search store to: ",
      newCountryFilter
    );
    commit("setCountry", newCountryFilter);
  },

  async updateMetricData({ commit }: any, requestParams: any) {
    // In this action, an api call is made for each metric to retrieve it's data.
    // The result is stored in state.metricData.
    // Data is stored for each metric based upon it's endpoint (unique)
    // For example:
    // state.metricData = {
    //   {'active-devices': 5},
    //   {'total-device': 10}
    // }
    // In MetricPanel.vue, it pulls in the data for each metric using it's endpoint as an index.

    const charts = CHART_DATA.charts; // Pull in the chart data from the config file.

    // For each metric (in the config file), make an api call for it's data.
    for (let i = 0; i < charts.length; i++) {
      try {
        const currentEndpoint = charts[i].api.endpoint; // Get the endpoint from the config file.

        // Make the api call.
        commit("setShowLoadingOverlay", true);
        const response = await metricApi.getData(
          SERVICES.EVENT,
          currentEndpoint,
          requestParams
        );
        console.log('updateMetricData:: response', response.data)

        // temporary manipulation of data for the app status table until we can fix the integration response from the api
        if (currentEndpoint == ENDPOINTS.APPLICATION_STATUS ){
          const items = transformEventsData(response)
          response.data.items = items
        }


        const currentMetricData = {
          [currentEndpoint]: response.data,
        };

        // the commit payload contains the data to be added and the endpoint, i.e
        // commitPayload = [
        //   'active-devices',
        //   {
        //     'active-devices' : 5
        //   }
        // ]
        // This format is required for the mutation to correctly add the data to the metric data state.
        const commitPayload = [currentEndpoint, currentMetricData];

        // Commit the data to the store.
        commit("setMetricData", commitPayload);

      } catch (error) {
        console.error(error);

        // TODO - Trigger an error toast

      } finally {
        // update the state of the overlay to false because the api calls are over.
        commit("setShowLoadingOverlay", false);
      }
    }
  },
  
  }
  function transformEventsData(backendResponse: any){
    const fieldsData: any = backendResponse.data.fields
    const eventsData: any = backendResponse.data.items
    const deviceData: any = {}

    /*combine data for one device into one object
    *input
    [
    {
        "device_id": "RQ3A.210705.001",
        "package": "com.bbc.news",
        "name": "BBC News",
        "status": "on"
    },
    {
        "device_id": "RQ3A.210705.001",
        "package": "com.sky.analytics_manager_companion",
        "name": "Analytics Manager Companion",
        "status": "on"
    }
    ]
    *output
    {
      "RQ3A.210705.001": {
        "BBC News": "on",
        "Analytics Manager Companion": "on"
      }
    }
    */
    eventsData.map((event: any) => {
      const device = event.device_id
      const app = event.name
      const appStatus = event.status
      if(deviceData[device]){
        const deviceObject = deviceData[device]
        deviceObject[app] = appStatus
      }
      else{
        deviceData[device] = {
          [app]: appStatus
        }
      }
      
    })

    const items = []
    let deviceId: String
    let deviceObject: any
    for ([deviceId, deviceObject] of Object.entries(deviceData)) {
      deviceObject.device_id=deviceId
      fieldsData.forEach((header:any) => {
        if(!deviceObject[header]){
          // Add a dash to any empty fields where app data doesn't exist for a particular device
          deviceObject[header]="-"
        }
      });
      items.push(deviceObject)
    }

    console.log(eventsData)
    console.log(deviceData)
    console.log(items)
    return items
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
