<template>
  <div class="header-div large-panel" id="header-div-id">
    <b-row
      align-v="center"
      class="header-row-class" 
      id="header-row-id">
      <b-col cols="1">
        <b-img class="w-65" src="https://www.sky.com/assets/masthead/images/sky-logo.png" />
      </b-col>
      <b-col
        class="font-size-xxx-large" cols="10">
        <div id="title-version-container">
          <h1 class="mr-2 mb-0">Cherry </h1>
          <div class="font-size-large">v{{ getVersionFormatted() }}</div>
        </div>
      </b-col>
      <b-col
        cols="1">
        <b-button
          v-if="userStatus === UserStatus.SignedIn || userStatus === UserStatus.TokenNotRenewable"
          variant="outline-primary"
          pill
          class="font-size-small mt-2 mb-2"
          id="sign-in-button"
          @click="$store.dispatch('user/signOut')">
            {{ getText(TEXT_KEYS.SIGN_OUT) }}
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { UserStatus, TEXT_KEYS } from '@/constants'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'Header',

  created () {
    this.UserStatus = UserStatus;
    this.TEXT_KEYS = TEXT_KEYS;
  },

  computed: {
    ...mapState('user', {
        userStatus: 'status'
    }),
    ...mapState('configuration', {
        version: 'configVersion'
    }),

   ...mapGetters('resourceTexts', [
      'getText'
    ])
  },

  methods: {
    getVersionFormatted() {
      return this.version ? `${this.version.version}.${this.version.build}` : ''
    }
  }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.header-row-class {
  height: 150px;
  max-height: 12vh;
}

#title-version-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
