<template>
  <div
    class="home-page"
    id="home-page-div">
    <Header />
    <div v-if="userStatus === UserStatus.NotSignedIn || userStatus === UserStatus.ErrorSignedIn || userStatus === UserStatus.TokenNotRenewable">
      <b-button
        pill
        variant="outline-secondary"
        class="font-size-medium mt-4 mb-2"
        id="sign-in-button"
        @click="$store.dispatch('user/signIn')">
          {{ getText(TEXT_KEYS.SIGN_IN) }}
      </b-button>
    </div>
    <div v-else-if="userStatus === UserStatus.SignedIn">
      <HomeTabManager />
    </div>
  </div>
</template>

<script>
/**
 * UI state
 * currentApplicationState === 'error'
 *  - shows the error page asking the user to sign in
 * currentApplicationState === 'signed-out'
 *  - shows the sign in button
 * currentApplicationState === 'signed-in'
 *  - shows the home tab navigator
 */
import Header from '../components/shared/Header.vue'
import HomeTabManager from '../components/home_page/HomeTabManager.vue'
import { COLOR_VARIANT,UserStatus, TEXT_KEYS  } from '../constants'
import { mapState,mapGetters  } from 'vuex'
import WebSocketApi from '@/api/webSocketApi'






export default {
  name: 'HomePage',
  components: {
    Header,
    HomeTabManager
  },

  created () {
    this.UserStatus = UserStatus;
    this.TEXT_KEYS = TEXT_KEYS;
  },

  computed: {
   ...mapState('user', {
       userStatus: 'status'
   }),
   ...mapGetters('resourceTexts', [
      'getText'
    ])
  },

  watch: {
    userStatus: function(newValue) {
      switch (newValue) {
        case UserStatus.ErrorSignedIn:
          this.$bvToast.toast(this.getText(TEXT_KEYS.TOAST_ERROR_SIGN_IN), {
            title: this.getText(TEXT_KEYS.TOAST_TITLE_ACCOUNT),
            variant: COLOR_VARIANT.DANGER
          })
          break
        case UserStatus.TokenNotRenewable:
          this.$bvToast.toast(this.getText(TEXT_KEYS.TOAST_ERROR_TOKEN), {
            title: this.getText(TEXT_KEYS.TOAST_TITLE_ACCOUNT),
            variant: COLOR_VARIANT.WARNING
          })
          break
        case UserStatus.SignedIn:
          WebSocketApi.getInstance().connect()
          break
      }
    }
  }
}
</script>