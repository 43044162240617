import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

const TIME_OUT = 35000

export default class AxiosApi {
  private static instance: AxiosApi

  private services: Record<string, AxiosInstance>

  private idToken: string
  /**
   * The Singleton's constructor should always be private to prevent direct
   * construction calls with the `new` operator.
   */
  private constructor () {
    this.services = {}
    this.idToken = ''
  }

  /**
   * The static method that controls the access to the singleton instance.
   *
   * This implementation let you subclass the Singleton class while keeping
   * just one instance of each subclass around.
   */
  public static getInstance (): AxiosApi {
    if (!AxiosApi.instance) {
      AxiosApi.instance = new AxiosApi()
    }

    return AxiosApi.instance
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public updateInstance (services: Array<any>): void {
    services.forEach((service: any) => {
      this.services[service.name] = axios.create({
        baseURL: service.api_url,
        timeout: TIME_OUT,
        headers: {
          'X-API-KEY': service.api_key
        }
      })
    })
  }

  public updateIdToken (token: string): void {
    this.idToken = token
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public post (serviceName: string, ...args: [string, any | AxiosRequestConfig]): any {
    args.push({
      headers: {
        Authorization: this.idToken
      }
    })
    return this.services[serviceName].post.apply(this, args)
  }

  public get (serviceName: string, ...args: [string, any | AxiosRequestConfig]): any {
    args.push({
      headers: {
        Authorization: this.idToken
      }
    })

    // We make sure if the params passed were null, that we remove them
    args = args.filter(function(axiosParameter: any) { return axiosParameter === 0 || axiosParameter }) as [string, any]

    return this.services[serviceName].get.apply(this, args)
  }
}
