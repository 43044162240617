<template>
  <div
    id="div-application-status-component">
    <TableMetric
        title="Devices Applications Status"
        description="Show all the devices and what applications is currently running "
        lastUpdated = "Last updated :"
        :data="getData"
        :date="getDate"
        :isLoading="getIsLoading"/>
    <div>

    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import WebSocketApi from '@/api/webSocketApi'
import TableMetric from '../metric/TableMetric.vue'


export default {
  name: 'ApplicationStatusComponent',

  components: {
    TableMetric
  },

  computed: {
    // Map the relevant action(s) + getter(s) + state from the store to this component
    ...mapGetters('applicationStatus', [
      'getData',
      'getDate',
      'getIsLoading'
    ]),
    ...mapState('webSocketStatus', {
      websocketConnected: 'websocketConnected',
    })
  },

  mounted() {
    // dispatch action which makes api calls for the data
    if(this.websocketConnected){
      WebSocketApi.getInstance().sendWebSocketMessage(
          '{"action": "onDataRequest", "tableName":"	analytics-manager-devices-applications-status"}'
        );
    }
  },
  watch: {
    websocketConnected: function (newValue) {
      if (newValue) {
        WebSocketApi.getInstance().sendWebSocketMessage(
          '{"action": "onDataRequest", "tableName":"	analytics-manager-devices-applications-status"}'
        );
      }
    }
  }

}
</script>
