import WebSocketApi from "@/api/webSocketApi";

const state = () => ({
    websocketConnected : false
  });

const getters = {
    getWebsocketConnected(state: any) {
        return state.websocketConnected;
    },
}  
const mutations = {
    setWebsocketConnected(state: any, newState: Boolean) {
        state.websocketConnected = newState; 
    },
}

const actions = {
    updateWebsocketConnected({ commit }: any, newState: Boolean) {
      console.log("updating websocket connected state to ",newState)  
      commit("setWebsocketConnected", newState);
    },
  };

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};