import Vue from 'vue'
import Vuex from 'vuex'
import search from './modules/searchStateControls'
import user from './modules/user'
import configuration from './modules/configuration'
import applicationStatus from './modules/applicationStatus'
import deviceInformation from './modules/deviceInformation'
import webSocketStatus from './modules/webSocketStatus'
import applicationMetrics from './modules/applicationMetrics'
import resourceTexts from './modules/resourceTexts'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    search,
    user,
    configuration,
    applicationStatus,
    deviceInformation,
    webSocketStatus,
    applicationMetrics,
    resourceTexts
  },
});
