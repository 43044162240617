import AxiosApi from "@/api/axiosConfig";

import { UserStatus } from '@/constants';

import MsalUtils from '@/utils/msal'

const state = () => ({
  name: "",
  email: "",
  accessToken: "",
  idToken: "",
  status: UserStatus.NotSignedIn
});

const getters = {
  getName(state: any) {
    return state.name;
  },

  getEmail(state: any) {
    return state.email;
  },

  getAccessToken(state: any) {
    return state.accessToken;
  },

  getIdToken(state: any) {
    return state.idToken;
  },

  getStatus(state: any): UserStatus {
    return state.status;
  }
};

const mutations = {
  setUser(state: any, newUser: Record<string, string>) {
    state.name = newUser.name;
    state.email = newUser.email;
    state.accessToken = newUser.accessToken;
    state.idToken = newUser.idToken;

    AxiosApi.getInstance().updateIdToken(state.idToken);
  },

  resetUser(state: any) {
    state.name = "";
    state.email = "";
    state.accessToken = "";
    state.idToken = "";
    state.status = UserStatus.NotSignedIn;

    AxiosApi.getInstance().updateIdToken("");
  },

  setStatus(state: any, newStatus: UserStatus) {
    state.status = newStatus;
  }
};

const actions = {
  signIn({ commit }: any) {
    MsalUtils.getInstance().aquireToken()
  },

  signOut({ commit }: any) {
    MsalUtils.getInstance().signOut()
  },

  resetUser({ commit }: any) {
    commit("resetUser")
  },

  updateUser({ commit }: any, newUser: Record<string, string>) {
    commit("setUser", newUser);
  },

  updateStatus({ commit }: any, newStatus: UserStatus) {
    commit("setStatus", newStatus);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};