<template>
  <div
    id="div-device-information-component">
    <TableMetric
        title="Devices Information"
        description="Show all the devices"
        lastUpdated = "Last updated :"
        :data="getData"
        :date="getDate"
        :isLoading="getIsLoading"/>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import WebSocketApi from '@/api/webSocketApi'
import TableMetric from '../metric/TableMetric.vue'

export default {
  name: 'DeviceInformationComponent',

  components: {
    TableMetric
  },

  computed: {
    // Map the relevant action(s) + getter(s) + state from the store to this component
    ...mapGetters('deviceInformation', [
      'getData',
      'getDate', 
      'getIsLoading'
    ]),
    ...mapState('webSocketStatus', {
      websocketConnected: 'websocketConnected',
    })
  },
  mounted(){
    WebSocketApi.getInstance().sendWebSocketMessage(
      '{"action": "onDataRequest", "tableName":"analytics-manager-devices"}'
    );
  },
  watch: {
    websocketConnected: function (newValue) {
      if (newValue) {
        WebSocketApi.getInstance().sendWebSocketMessage(
          '{"action": "onDataRequest", "tableName":"analytics-manager-devices"}'
        );
      }
    }
  },
    
  
}
</script>
