export const SERVICES = {
    EVENT: 'event'
};

export const TABLE_NAMES = {
  APP_STATUS: "analytics-manager-devices-applications-status",
  DEVICES: "analytics-manager-devices"
};

export const WEBSOCKET_ACTIONS = {
  DATA_REQUEST: "onDataRequest"
}

export const ENDPOINTS = {
    APPLICATION_STATUS: 'application-status',
    DEVICES: 'devices',
    APPLICATIONS: 'applications'
}

export const DATETIME_FORMAT = "YYYY-MM-DD HH:mm:ss";

export const COLOR_VARIANT = {
  SUCCESS: "success",
  DANGER: "danger",
  WARNING: "warning",
  PRIMARY: "primary",
  SECONDARY: "secondary",
  INFO: "info",
  LIGHT: "light",
  DARK: "dark",
}

export const enum UserStatus {
  NotSignedIn,
  ErrorSignedIn,
  TokenNotRenewable,
  SignedIn
}

export const TEXT_KEYS: Record<string, string> = {
  TOAST_TITLE_ACCOUNT: "toast_title_account",
  TOAST_ERROR_SIGN_IN: "toast_sign_in_error",
  TOAST_ERROR_TOKEN: "toast_token_error",

  SIGN_IN: "sign_in",
  SIGN_OUT: "sign_out",

  TITLE_APPLICATION_USAGE: "title_applications_usage",
  BUTTON_PULL_DATA: "button_pull_data",

  APP_METRICS_PULL_ERROR: "application_metrics_pull_error"
}

export const PLOTLY_TYPE: Record<string, string> = {
  BAR: 'bar',
  PIE: 'pie'
}