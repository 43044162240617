<template>
  <div
    id="div-application-metrics-component">
    <div>
      <b-button
        pill
        variant="outline-secondary"
        class="mt-2 mb-2"
        :disabled="isLoading"
        @click="handleClick">
          {{ getText(TEXT_KEYS.BUTTON_PULL_DATA)}}
      </b-button>
    </div>
    <h2
        class="mt-4 mb-4">
        {{ getText(TEXT_KEYS.TITLE_APPLICATION_USAGE) }}
    </h2>
    <b-row
        v-if="data"
        align-h="around"
        class="h-100">
        <b-col
            cols="5">
            <b-card>
              <Plotly
                  :data="barData.data"
                  :display-mode-bar="true"></Plotly>
            </b-card>
        </b-col>
        <b-col
            cols="5">
            <b-card>
              <Plotly
                  :data="pieData.data"
                  :display-mode-bar="false"></Plotly>
            </b-card>
        </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import { Plotly } from 'vue-plotly'

import { COLOR_VARIANT, TEXT_KEYS, PLOTLY_TYPE } from '@/constants'

export default {
  name: 'ApplicationMetricsComponent',

  components: {
    Plotly
  },

  created () {
    this.TEXT_KEYS = TEXT_KEYS;
  },

  data() {
    return {
      barData: {
        data:[{
            x: [],
            y: [],
            type: PLOTLY_TYPE.BAR
        }]
      },

      pieData: {
        data:[{
            values: [],
            labels: [],
            type: PLOTLY_TYPE.PIE
        }]
      }
    }
  },

  computed: {
    ...mapState('applicationMetrics', {
        data: 'data',
        isLoading: 'isLoading'
    }),
   ...mapGetters('resourceTexts', [
      'getText'
    ])
  },

  watch: {
    isLoading: function(newValue) {
        if (!newValue) {
            if (this.data) {
                // Format for the charts
                this.barData.data[0].x = this.data.fields
                this.barData.data[0].y = this.data.values

                this.pieData.data[0].labels = this.data.fields
                this.pieData.data[0].values = this.data.values
            } else {
                this.$bvToast.toast(this.getText(TEXT_KEYS.APP_METRICS_PULL_ERROR), {
                    title: this.getText(TEXT_KEYS.TITLE_APPLICATION_USAGE),
                    variant: COLOR_VARIANT.DANGER
                })
            }
        }
    }
  },

  methods: {
    handleClick() {
      // dispacth action which makes api calls for the data
      this.$store.dispatch('applicationMetrics/loadData')
    }
  }
}
</script>
